/**
 * KeyFrames for the navigation
 */ 
@include keyframes(showNav) {
  from {
    opacity: 0;
    z-index: -9999;
  }

  to {
    opacity: 1;
    z-index: 1;
  }
}

@include keyframes(hideNav) {
  from {
    opacity: 1;
    z-index: 1;
  }

  to {
    opacity: 0;
    z-index: -9999;
  }
}

@include keyframes(overlayFade) {
  from {
    opacity: 0;
    z-index: -9999;
  }

  to {
    opacity: 1;
    z-index: 999999;
  }
}

@include keyframes(overlayHide) {
  from {
    opacity: 1;
    z-index: 999999;
  }

  to {
    opacity: 0;
    z-index: -9999;
  }
}

.nav-primary-drop-icon {
  font-weight: 900;
  @include font-size(10);
}

@include keyframes(slideOpen) {
  from {
    transform: translate3d(0, 0, 0);
    //@include transform(translate3d(0px, 0px, 0px));
  }

  to {
    transform: translate3d(285px, 0, 0);
    //@include transform(translate3d(285px, 0px, 0px));
  }
}

@include keyframes(slideClosed) {
  from {
    //@include transform(translate3d(285px, 0px, 0px));
    transform: translate3d(285px, 0, 0);
  }

  to {
    //@include transform(translate3d(0px, 0px, 0px));
    transform: translate3d(0, 0, 0);
  }
}

/**
 * Navigation Styles
 */
.navigation-ul {
  padding: 0;
}

.nav-main-link, .nav-drop-link {
  color: $navigation-link-color;
}


/*******************************
    Primary Navigation
/*******************************/
.nav-primary {
  .nav-primary-ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  .nav-main {

    &-link {
      // padding: 0.75em 1.5em;
      padding: 10px 0;

      margin: 20px 0;
      text-align: center;
      display: inline-block;
      @include font-size(16);
      font-weight: bold;
      border-bottom: 1px solid transparent;

      &:hover {
        
      }
    }

    &-item {
      margin: 0 20px;

      &:last-of-type {
        margin-right: 30px;
      }

      
      &:hover {  

        .nav-main-link {
          text-decoration: none;
          color: map-get($theme-colors, primary);
          border-bottom: 1px solid map-get($theme-colors, primary);
        }

        & > .nav-primary-drop-icon {
          color: map-get($theme-colors, primary);
        }

        & > .nav-drop {
          transform: translateY(100%);
          opacity: 1;
          z-index: 999999999;
          visibility: visible;
        }
      }

      &:last-of-type {
        .nav-drop {
          right: 0;
          left: initial;
        }
      }

    }

    .nav-primary-drop-icon {
      @include font-size(8);
    }

  }

  .nav-main-item, 
  .nav-drop-item {
    position: relative;
  }

  // Dropdown
  .nav-drop {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #F8F8F8;
    width: 240px;
    padding: 25px;

    &:not(.nav-grand-drop) {
      // Animation Styles
      transform: translateY(125%) translateZ(0);
      opacity: 0;
      z-index: -9999999;
      visibility: hidden;
      will-change: transform;
      transition: transform .35s ease-in-out, opacity .5s ease-in-out;
      // transition-delay: 1.5s; 
    }

    &-item {

      &.active {
        & > .nav-grand-drop {
          overflow: visible;
          max-height: 10000px;
          opacity: 1;
        }
      }

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      &:hover {
        .nav-drop-link {
          text-decoration: none;
        }

        &.menu-item-has-children {
          
        }
      }
    }
    
    &-link-icon {
      @include font-size(14);
      color: map-get($theme-colors, primary);
      display: inline-block;
      font-weight: 300;
      opacity: 0.8;
      transition: opacity .25s ease;
      cursor: pointer;
    }

    &-link {
      @include font-size(14);
      padding: 10px 0;
      color: map-get($theme-colors, primary);
      display: inline-block;
      font-family: $font-family-roboto;
      font-weight: 300;
      opacity: 0.8;
      transition: opacity .25s ease;

      &:hover {
        text-decoration: none;
        opacity: 1;
      }
    }

    /*******************************
        Grand Drop Menu Styles
    /*******************************/
    &.nav-grand-drop {
      position: relative;
      left: 0;
      bottom: 0;
      transform: none;
      background-color: transparent;
      width: 100%;
      padding: 0;

      // Animation styles
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition: max-height .25s linear, opacity .25s linear; 

      .nav-grand-drop-item {
        margin: 2.5px 0;
      }

      .nav-grand-drop-link {
        @include font-size(12);
        color: map-get($theme-colors, secondary);
        padding: 5px 0;
        opacity: 0.7;

        &:hover {
          border-bottom: 1px solid map-get($theme-colors, primary);
          opacity: 1;
          text-decoration: none;
        }
      }
    }
  }
}

/*******************************
    Mobile Menu Styles
/*******************************/
.mobile-nav-primary {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 285px;
  //background-color: map-get(map-get($rsv-theme-colors, gray), base);
  background-color: #fff;
  z-index: -9999;
  overflow-x: hidden;
  overflow-y: scroll;
  display: none;
  opacity: 0;
  -webkit-overflow-scrolling: touch;

  @include animation(0, 0.3s, ease-out, hideNav);

  @include media-breakpoint-up(lg) {
    display: none !important;
    z-index: -9999999;
    pointer-events: none;
  }
}

.mobile-nav-top--wrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.nav-mobile-primary-close--wrapper {
  .close-icon,
  .close {
    color: map-get($theme-colors, secondary);
    @include font-size(12);
    font-family: $font-family-muli;
    font-weight: normal;
  }
}

#menu-mobile-menu {

  .nav-main-item {
    padding: 0 0 20px;

    &.menu-item-has-children::after {
      content: '';
      clear: both;
      display: table;
    }

    &.nav-grand-drop-item {

    }

    .nav-main-drop-icon {
      color: map-get($theme-colors, secondary);
      @include font-size(10);
      line-height: 1.5;
      vertical-align: middle;
      float: right;
      font-weight: bold;
      padding: 5px;
    }

    &.current-menu-parent {
      .nav-main-link {
        color: map-get($theme-colors, primary);
      }
    }

  }

  .nav-main-link {
    @include font-size(16);
    font-weight: bold;
  }

  /*******************************
      Drop Nav Styles
  /*******************************/
  .nav-drop {
    padding: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, -50px, 0);
    overflow: hidden;
    transition: transform .5s linear, opacity .5s linear;

    &.active {
      padding: 20px 15px 0; 
      transform: none;
      opacity: 1;
      height: auto;
      overflow: visible;
      visibility: visible;
    }
  }

  .nav-drop-item {
    padding: 0 0 20px;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .nav-drop-link {
    color: map-get($theme-colors, secondary);
    @include font-size(14);

    &.nav-grand-drop-link {
      color: rgba(0,0,0,0.5);
    }
  }
}


/********************
    SOCIAL MEDIA
/********************/

.mobile-nav-social--wrapper {
  padding-left: 0;

  .social-media {
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .social-media--item {

  }

  .social-media-link {
    color: map-get($theme-colors, secondary);

    &:hover,
    &:active {
      color: map-get($theme-colors, primary);
    }
  }
}

.nav-mobile-primary-close--wrapper,
.header--menu {
  cursor: pointer;
}

.header--menu {
  p {
    font-weight: 500;
    color: rgba(0,0,0,0.75);
    text-transform: capitalize;
  }

  i {
    font-weight: 100;
    -webkit-text-stroke: 1px solid $white;
  }
}

// @see navigation.twig -> should be the ul
#menu-mobile-menu {
  flex-direction: column;
}

html {
  position: relative;
  -webkit-overflow-scrolling: touch;
  min-width: 100%;
  height: auto;
  overflow-y: auto !important;

  body {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    min-width: 100%;
    height: auto;
    overflow-y: auto !important;
  }

  &::after {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 285px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    z-index: -9999;

    @include animation(0, 0.15s, linear, overlayHide);
  }

  & .site--wrapper {
    @include animation(0, 0.3s, linear, slideClosed);

    position: relative;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
  }

  &.loading {
    &::after {
      @include animation(0, 0, none, none);
    }

    & .site--wrapper {
      @include animation(0, 0, none, none);
    }

    & .mobile-nav-primary {
      @include animation(0, 0, none, none);
    }
  }

  &.nav-open {
    &::after {
      @include animation(0.3s, 0.3s, linear, overlayFade);
    }

    & .site--wrapper {
      @include animation(0, 0.3s, ease-out, slideOpen);
    }

    .mobile-nav-primary {
      @include animation(0.3s, 0.3s, ease-out, showNav);

      display: block;
    }
  }
}













