.block {}

.tmp-slider-navigation {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    &--dots_wrapper {
        border: 0.5px solid #d6d6d6;
        border-radius: 20px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 8px 5px;
    }

    &_dot {
        line-height: 0;
        margin: 0 7px;
    }
}

/**************************
   Content Display Blocks
/**************************/
.cdb-item {

    &--wrapper {
        padding: 25px 15px;

        @include media-breakpoint-up(sm) {
            padding: 45px 25px;

            &:not(.last) {
                // position: relative;

                &::after {
                    content :'';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 1px;
                    height: 100%;
                    background-color: rgba(0,0,0,0.15);
                    
                }
            }
        }

        @include media-breakpoint-up(md) {
            padding: 55px 25px;

        }

        &:hover {
            .cdb-item--image {
                opacity: 1;
            }

            .cdb-item--icon {
                opacity: 0;
            }

            .cdb-item_hover--icon {
                opacity: 1;
            }

            .cdb-item--title,
            .cdb-item-content--wrapper * {
                color: $white;
            }

            .cdb-item--link { 
                color: map-get($theme-colors, primary);
            }
        }
    }

    &--image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        transition: opacity .25s ease-in-out;
    }

    &-icon--wrapper {
        position: relative;
        height: 80px;
        width: 80px;
        margin-bottom: 65px;
        // background: #000;
    }

    &--icon,
    &_hover--icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        // transition: opacity .5s linear;
    }

    &--inner {}

    &--icon {}

    &_hover--icon {
        opacity: 0;
    }

    &--title {
        margin-bottom: 35px;
    }

    &-content--wrapper {
        margin-bottom: 55px;
        margin-left: -15px;
        margin-right: -15px;
    }

    &--link {
        color: map-get($theme-colors, secondary);
        @include font-size(10.5);
        font-weight: bold;
    }

}


/**************************
   Page Introductions
/**************************/
.page-introduction.service-landing {
    margin: 30px 0;
}


/**************************
   Services Sidebar
/**************************/
.service-sidebar--wrapper {
    margin-top: 36px;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
}

.service-cta {
    &--wrapper {
        width: 360px;
        max-width: 100%;
        margin: 0 auto;
        padding: 30px;
        background-color: #F8F8F8;
        margin-bottom: 36px;
    }

    &--title {
        @include font-size(18);
        color: map-get($theme-colors, secondary);
        font-weight: bold;
        text-align: center;
        line-height: 1.3333333333;
        margin-bottom: 20px;
    }

    &--button {
        display: block;
        width: 100%;
        padding: 10px 30px;
        cursor: pointer;
    }
}


/**************************
   Past Clients
/**************************/ 
.past-client--block {
    margin: 35px 0;

    &:nth-child(even) {
        .row > .col:nth-child(1) {
            @include media-breakpoint-up(md) {
                order: 2;
            }
        }

        .row > .col:nth-child(2) {
            @include media-breakpoint-up(lg) {
                .past-client-content--wrapper,
                .past-client-meta--wrapper {
                    padding-left: 60px;
                }   
            }
        }
    }   
}

.past-client--image {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;

    @include media-breakpoint-up(sm) {
        width: 320px;
    }
}

.past-client-content--wrapper {
    p, a {
        @include font-size(18);
        font-family: $font-family-roboto;
        font-style: italic;
    }
    p {
        color: rgba(0, 0, 0, 0.5);
        line-height: (28 / 20);
    }
}

.past-client-content--wrapper,
.past-client-meta--wrapper {
    @include media-breakpoint-up(lg) {
        padding-right: 60px;
    }
}

.past-client-content--wrapper {
    margin-bottom: 35px;
}

.past-client--title {
    @include font-size(20);
    color: map-get($theme-colors, secondary);  
    margin-bottom: 10px;
}

.past-client--position {
    color: rgba(0,0,0,0.5);
    font-family: $font-family-roboto;
    line-height: (20 / 28);
    display: block;
}


/**************************
   Our Team
/**************************/ 
.team-members-row {
    @include media-breakpoint-up(md) {
        margin-top: -50px;
    }
}
.team--wrapper {
    margin-bottom: 100px;
    @include media-breakpoint-up(md) {
        margin-bottom: 250px;
    }
}

.team-member {

    &--wrapper {
        margin: 50px 0;
        position: relative;
    

        &:hover {
            .team-member--title,
            .team-member--position {
                color: map-get($theme-colors, primary);
            }

            .team-member--feature {
                
                box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
            }
        }
    } 

    &--link {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
    }

    &--feature {
        width: 200px;
        max-width: 100%;
        margin: 0 auto 25px;
        box-shadow: 0 4px 8px 0 transparent;
        transition: box-shadow .5s ease-in-out;
    
    }

    &--title {
        color: map-get($theme-colors, secondary);
        @include font-size(20);
        line-height: 1.3;
        margin-bottom: 1rem;
    }

    &--position {
        @include font-size(14);
        color: rgba(0,0,0,0.5);
        font-family: $font-family-roboto;
        font-weight: normal;
        line-height: 1.3;
    }
    
}

.contact-introduction--wrapper {
    @include media-breakpoint-up(md) {
        padding: 100px 0;
        border-left: 1px solid #979797;
        padding-left: 25px;
    }

    @include media-breakpoint-up(xl) {
        padding-left: 0;
        margin-left: 25px;
    }
}

.contact-info--title {
    color: map-get($theme-colors, secondary);
    @include font-size(12);
    letter-spacing: 0.75px;
    margin-bottom: 15px;
}

.contact-info--content {
    font-family: $font-family-roboto;
    color: rgba(0,0,0,0.5);
    @include font-size(16);
    line-height: ( 24 / 16 );
}

.contact-info--wrapper {
    padding: 0 40px;

    @include media-breakpoint-up(md) {
        padding: 0 10px;
    }

    @include media-breakpoint-up(xl) {
        padding: 0 35px;
    }
} 

.contact-information {
    margin-bottom: 60px;
}

.contact-social {
    &--item {
        &:not(:last-of-type) {
            margin-bottom: 5px;

            @include media-breakpoint-up(md) {
                margin-bottom: 10px;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 5px;
            }
        }

        width: 100%;
        padding: 0 40px;

        @include media-breakpoint-up(md) {
            padding: 0;
        }
    }

    &--link,
    &--icon {
        color: map-get($theme-colors, secondary);
        @include font-size(14);
        font-weight: bold;

        @include media-breakpoint-up(lg) {
            @include font-size(16);
        }
    }

    &--link {
        font-family: $headings-font-family;
        @include media-breakpoint-up(md) {
            // text-align: center;
            display: block;
        }

        @include media-breakpoint-up(lg) {
            text-align: left;
            display: inline-block;
        }
    }

    &--icon {
        margin-right: 10px;
        display: inline;

        @include media-breakpoint-up(md) {
            margin-bottom: 5px;
        }
    }
}

.contact-introduction_inner--wrapper {
    width: 515px;
    max-width: 100%;
    margin: 0 auto;

    h1, h2, h3, h4, h5, h6 {
        color: map-get($theme-colors, secondary);
    }
}

/**************************
   Services Landing Slider
/**************************/
.service-landing-slider--wrapper {
    padding: 50px 0;
    background-color: #F8F8F8;
}

.sl--slider {
    text-align: center;
}
@include media-breakpoint-up(xl) {
    .sl-slider--content {
        max-width: 270px;
    }
}
.sl-slider {

    &-inner--wrapper {
        @include media-breakpoint-up(md) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;

            .sl-slider--title {
                min-width: 170px;
                padding-right: 15px;
            }
        }

        @include media-breakpoint-up(lg) {
            .sl-slider--title {
                max-width: 240px;
                padding-right: 50px;
            }
        }
    }
    
    &--title {
        color: map-get($theme-colors, secondary);
        @include font-size(28px);
        line-height: (34 / 28);
    }

    &--content {
        color: rgba(0,0,0,0.5);
        @include font-size(16);
        line-height: (24 / 16);
    }
}

.sl-slide {
    &--article {
        position: relative;
        padding: 25px;
        background-color: transparent;
        transition: background-color .5s ease;
        width: 265px;
        max-width: 100%;

        &:hover {
            background-color: #fff;
        }

        footer {
            border-top: 1px solid rgba(0,0,0,0.1);
            padding: 15px 0;
        }
    }

    &--title { 
        @include font-size(20);
        font-weight: 800;
        line-height: (26 / 20);
        margin-bottom: 25px;
    }

    .entry-content {
        p {
            color: map-get($theme-colors, secondary);
            @include font-size(16);
            line-height: (24 / 16);
        }
        margin-bottom: 35px;
    }

    &--link {
        @include font-size(12);
        font-family: $font-family-muli;
        letter-spacing: 0.75px;
        color: map-get($theme-colors, secondary);
        font-weight: 800;

        &:hover {
            text-decoration: none;
            color: map-get($theme-colors, primary);
            padding: 13px 0;
        }
    }
}

.block--link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/*************************************
   Business & Services Requirements 
/*************************************/
.requirements-block {
    padding: 80px 0;
    background-color: #F8F8F8;

    &-title--wrapper {
        margin-bottom: 70px;
    }
    
    &--title {
        color: map-get($theme-colors, secondary);
        text-align: center;
        margin: 0;
        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }

    /********
      Tabs
    /********/
    &-tabs--wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 40px;

        .rb-tab {
            width: 165px;
            max-width: 50%;
            padding: 25px 5px;
            display: inline-block;
            border-bottom: 3px solid #C6CBD3;
            transition: border-bottom .25s linear;
            cursor: pointer;

            &.active,
            &:hover {
                border-bottom: 3px solid map-get($theme-colors, primary);

                .tab--title {
                    color: map-get($theme-colors, primary);
                }
            }

            @include media-breakpoint-up(md) {
                width: 200px;
                
                .tab--title {
                    @include font-size(14);
                }
            }
        }

        .tab--title {
            @include font-size(16);
            color: rgba(0,0,0,0.5);
            line-height: (24 / 16);
            margin: 0;
            transition: color .25s linear;
        }
    }

    /**********
      Content
    /**********/
    &-content--wrapper {
        width: 900px;
        max-width: 100%;

        .rb-content {
            margin-bottom: 42px;

            z-index: 10;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            visibility: visible;
            height: auto;
            will-change: transform;
            transition: transform .5s linear, opacity 1s linear;

            &:not(.active) {
                z-index: -99999999;
                opacity: 0;
                transform: translate3d(-50px, 0, 0);
                visibility: hidden;
                height: 0;
                margin-bottom: 0;
            }

            h1, h2, h3, h4, h5, h6 {
                color: map-get($theme-colors, secondary);
            }

            p {
                @include font-size(20);
                line-height: (28 / 20);
            }
        }
    }

    /**********
      Lists
    /**********/
    &-list--wrapper {
        z-index: 10;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        visibility: visible;
        height: auto;
        will-change: transform;
        transition: transform .5s linear, opacity 1s linear;


        &:not(.active) {
            z-index: -99999999;
            opacity: 0;
            transform: translate3d(-50px, 0, 0);
            visibility: hidden;
            height: 0;
            
        }

        @include media-breakpoint-up(md) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;

            .rb-list-container {
                width: 50%;
            }
        }

        .rb-list {
            &-container {}

            &--title {
                @include font-size(12);
                color: map-get($theme-colors, secondary);
                letter-spacing: 0.75px;
                line-height: (20 / 12);
                margin-bottom: 23px;
            }

            &--item {
                font-size: 1rem;
                font-family: $font-family-roboto;
                padding: 10px;
                color: rgba(0,0,0,0.5);
                line-height: (45 / 20);
                font-weight: normal;

                &::before {
                    
                }

                &:not(:last-of-type) {
                    margin-bottom: 5px;
                }

            }

        }
    }
}

.owl-nav {

    .owl-next,
    .owl-prev {

        line-height: 0;

        &:hover {
            background: transparent !important;
        }

        &.disabled {
            color: rgba(134,142,156,0.3)!important;
        }

        color: map-get($theme-colors, primary) !important;

        font-size: (30 / 16) + rem !important;
    }

}

// .testimonial-text {
//     color: rgba(0,0,0,0.9);
//     font-family: $font-family-roboto;
//     @include font-size(14);
//     font-style: italic;
//     line-height: (1.5);
// }

.testimonials-slider-navigation--wrapper,
.sl-slider-control--container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    .testimonials-slider-pagination--wrapper,
    .sl-slider-control--pagination {
        margin: 0 35px;
    }

    .testimonials-slider-arrow--wrapper,
    .sl-slider-arrow--wrapper {
        display: inline-block; 
        padding: 0 5px;
        text-align: center;

        i {
            cursor: pointer;
            color: rgba(134,142,156,0.5);
            @include font-size(24);
            // opacity: 0.5;
            vertical-align: middle;
            transition: color .25s linear;

            &:hover,
            &:focus,
            &:active {

                // opacity: 1;
                color: map-get($theme-colors, primary);
            }
        }
    }
}

.owl-dots {
    border: 0.5px solid rgba(134,142,156,0.3);
    display: inline-block;
    padding: 7px;
    border-radius: 100px;
    line-height: 0;
    position: relative;

    .owl-dot {
        padding: 0;
        border: none;
        background: transparent;
        outline: none;
        cursor: pointer;

        span {
            background: rgba(134,142,156,0.3) !important;
            height: 8px !important;
            width: 8px !important;
        }
        
        &.active span {
            background: map-get($theme-colors, primary) !important;
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        height: 1px;
        width: 25px;
        background: rgba(134,142,156,0.3);
    }

    &::before {
        left: 0;
        transform: translateX(-100%);
    }


    &::after {
        right: 0;
        transform: translateX(100%);
    }
}


/*************************************
   Visa Form Progress
/*************************************/
.visa-form--wrapper {
    margin-bottom: 100px;
}

.visa-form_progress--wrapper {
    display: flex;
    flex-flow: row nowrap;
    .vsp-section--wrapper {
        border-top: 3px solid #C6CBD3;
        width: 165px;
        max-width: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        padding: 12.5px 15px;

        .vsp-label-indicator--wrapper {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #D8D8D8;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            .number,
            .tick {
                color: #fff;
                @include font-size(16);
                font-family: $font-family-roboto;
                line-height: 0;
                display: inline;
                vertical-align: middle;
            }

            .number {

            }

            .tick {
                display: none;
            }
        }

        .vsp-label-text--wrapper {
            margin-left: 5px;
            span {
                @include font-size(10);
                line-height: 2;
                color: rgba(0,0,0,0.5);
            }
        }

        &.first {
            border-top: 3px solid map-get($theme-colors, primary);
        }

        &.second {}

        // in-progress
        // finished

        &.in-progress {
            .vsp-label-indicator--wrapper {
                background: #000;
                .number {
    
                }
    
                .tick {
    
                }
            }

            .vsp-label-text--wrapper {
                span {
                    color: #000;
                }
            }
        }

        &.finished {
            border-top: 3px solid map-get($theme-colors, primary);
        }

    }
}


/*************************************
   FOREX STEPS 
/*************************************/
.forex-steps {
    &--wrapper {
        // margin-bottom: 50px;
        padding: 50px;
        background-color: #F8F8F8;

        @include media-breakpoint-up(md) {
            padding: 80px 0;
        }

        @include media-breakpoint-up(lg) {
            // margin-bottom: 70px;
        }
    }

    &--title {
        color: map-get($theme-colors, secondary);
    }
}

.forex-step {

    &--wrapper {
        background: transparent;
        padding: 50px 15px;
        margin: 15px 0;

        @include media-breakpoint-up(md) {
            margin: 0;
        }

        @include media-breakpoint-up(lg) {
            padding: 75px 30px;
        }
    }

    &-icon--wrapper {
        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 70px;
        }
    }

    &--icon {
        height: 50px;
        width: 50px;
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
            height: 100px;
            width: 100px;
        }
    }

    &-title--wrapper {
        margin-bottom: 30px;
    }

    &-title {}

    &-content--wrapper {
        margin-bottom: 30px;
    }

    &--link {
        color: map-get($theme-colors, secondary);
        @include font-size(10);
        font-weight: 800;
        letter-spacing: 0.75px;
    }

    /************
       HOVER
    ************/
    &--wrapper {
        &:hover {
            background: #fff;
            &-icon--wrapper {

            }
        
            &-icon {}
        
            &-title--wrapper {}
            &-title {}
        
            &-content--wrapper {}
        
            .forex-step--link {
                color: map-get($theme-colors, primary);
            }
        }
    }
   
}

.post-block-link {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

article.type-post {
    position: relative;
}

.testimonials-slider-navigation--wrapper.past-clients-pagination {
    
    .testimonials-slider-arrow--wrapper,
    .sl-slider-arrow--wrapper {
        display: inline-flex; 
        padding: 0 5px;
        text-align: center;

        display: flex;
        align-items: center;

            &:hover,
            &:focus,
            &:active {
                // opacity: 1;
                color: map-get($theme-colors, primary);
                text-decoration: none;

                i, span {
                    color: map-get($theme-colors, primary);
                }
            }

        i, span {
            cursor: pointer;
            color: rgba(134,142,156,0.5);

            // opacity: 0.5;
            vertical-align: middle;
            transition: color .25s linear;
        }

        i {
            
            @include font-size(24);
            
        }

        span {
            @include font-size(16);
        }
    }

}
