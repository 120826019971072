.brand {
  display: inline-block;
  max-width: 100%;
  z-index: 5;

  &:not(.nav-brand) {
    position: absolute;
    left: 15px;
    bottom: 0;
    transform: translateY(75%);

    @include media-breakpoint-up(lg) {
      left: 0;
      .brand--logo {
        width: 150px !important;
      }
    }
  }

  &--wrapper {
    text-align: center;   
  }  

  &--logo {
    width: 90px;
    max-width: 100%;
    
    // height: 80px;
    // width: 100%;
  }
}

.header--menu {
  padding: 10px 0;
}

.site-header_top--wrapper {
  background: #000;
}

.site-header {
  position: relative;
}

/****************
   Header Top 
/***************/
.site-header_top--inner {
  padding: 5px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-up(sm) {
    justify-content: flex-end;  
    padding: 8px 0;
  } 
}

.site-header_top-navigation--wrapper,
.site-header_top-contact--wrapper {
  line-height: 1;

  @include media-breakpoint-up(sm) {
    line-height: 0;
  }
}

.site-header_top-navigation--wrapper {
  display: block;
}

.site-header_top-inner-navigation--wrapper {
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }
}

.site-header_top-contact--wrapper {
  display: block;
  text-align: center;

  a:hover,
  &:active,
  &:focus {
    color: map-get($theme-colors, primary);
    i,
    span {
      color: map-get($theme-colors, primary);
    }
  }

  i,
  span {
    color: $white;
    @include font-size(12);
    transition: color .15s linear;
  }
}

/*******************************
    Sub Primary Navigation
/*******************************/
.nav-sub-primary {
  width: 100%;
  
  & > ul {
    margin-bottom: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      flex-flow: row nowrap;
    }
  }

  .nav-main-item {
    margin: 0 5px;
  }

  .nav-main-link {
    @include font-size(12);
    padding: 4px 7px;
    color: $white;
    display: inline-block;
  
  } 
}

.site-header_bottom--wrapper {
  position: relative;
  padding: 8px 0;  
  z-index: 50;

  @include media-breakpoint-up(lg) {
    padding: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: hsla(0,0%,100%,.8);
  }
}

.header-assesssment-link--wrapper {
  .free-assessment--wrapper {
    
  }
}

.page-template.page-template-template-contact.page-template-template-contact-php {

  .site-header_top--wrapper,
  .site-header_bottom--wrapper {
    z-index: 20;
    position: relative;
  }

  .contact-page-header--map {
    z-index: 10;
  }
}