.block.page-builder {
  padding: $page-builder-block-spacing 0;
}

/*
 * Banner Images
 * @see pb-banner-image-block-layout.twig
 */
.page-builder-banner--block {
  .pb--banner {
    display: block;
    position: relative;
    width: 100%;

    &::before {
      content: "";
      display: block;
      width: 100%;
      transition: padding-bottom 0.5s ease;

      @include media-breakpoint-up(md) {
        padding-bottom: 56.25%;
      }
    }
  }

  &.standard {
    .pb--banner {
      &::before {
        padding-bottom: 75%;

        @include media-breakpoint-up(sm) {
          padding-bottom: 60%;
        }

        @include media-breakpoint-up(xl) {
          padding-bottom: 45%;
        }
      }
    }
  }

  &.full-width {
    .pb--banner {
      &::before {
        padding-bottom: 75%;

        @include media-breakpoint-up(sm) {
          padding-bottom: 65%;
        }

        @include media-breakpoint-up(xl) {
          padding-bottom: 45%;
        }
      }
    }
  }
}

/*
 * Slider
 * @see pb-page-slider-block-layout.twig
*/
.pb--slider {
  // Styling for middle arrows
  &.middle-arrows {
    .owl-nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      z-index: 10;
      padding: 0 10px;

      .owl-prev,
      .owl-next {
        &::before {
          font-family: FontAwesome;
          color: #fff;
          font-size: 1rem;
          line-height: 1;
        }

        span {
          display: none;
        }
      }

      .owl-prev::before {
        content: "\f060";
      }

      .owl-next::before {
        content: "\f061";
      }
    }
  }

  &.sc-dots-styling {
    .owl-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;

      .owl-dot {
        span {
          background: map-get($theme-colors, secondary);
        }

        &.active {
          span {
            background: map-get($theme-colors, primary);
          }
        }
      }
    }
  }
}

.pb--slide {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
  }
}

/*
 * Featherlight Styles
*/
.with-featherlight {
  .featherlight .featherlight-content {
    border-bottom: none;
    padding: 0;
  }
}

/*******************************
    Testimonials Slider
/*******************************/
.testimonials-slider--wrapper {
  padding: 80px 0;
  position: relative;
}

.testimonials-slider--quotes {
  width: 240px;
  height: 190px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(-25%, -25%, 0);
  opacity: 0.4;
  z-index: -1;

  @include media-breakpoint-up(md) {
    transform: none;
    opacity: 1;
    top: 60px;
    left: 80px;
  }
}

.testimonials-slider-title--wrapper {
  margin-bottom: 40px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 60px;
  }
}

.testimonials-slide {
  margin: 0px 0 40px;
 
  .ts {
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.1);
    border: 1px solid #EFEFEF;
    border-radius: $border-radius;
    padding: 25px;
    background-color: #FFFFFF;

    @include media-breakpoint-up(sm) {
      padding: 36px;
    }

    &--summary {
      margin-bottom: 30px;
      p, a, span{
        color: map-get($theme-colors, secondary);
        @include font-size(14);
        font-style: italic;
        margin-bottom: 0.75rem;
      }
    }

    &--title {
      @include font-size(16);

    }
  }

}

.testimonials-text,
.testimonial-text {
  color: map-get($theme-colors, secondary);
  @include font-size(14);
  font-style: italic;
}

.testimonials-slider {
  text-align: center;
}


/*******************************
    Two Column Text Block
/*******************************/
.block.page-builder.page-builder--text-block_two-column {
  background:#EFEFEF;
  padding: 60px 0;

  @include media-breakpoint-up(md) {
    padding: 150px 0;
  }
}


/*******************************
    Image W Caption
/*******************************/
.block.page-builder.page-builder--image-w-caption-layout {

  &.full {

    @include media-breakpoint-up(xl) {
      padding: 90px 0;
    }

    @media all and (min-width: 1800px) {

      &.image-left { 
        .page-builder--image-w-caption_image--wrapper {
          margin-left: auto !important;
          margin-right: unset !important;
          max-width: 600px;
        }
      }

      &.image-right { 
        .page-builder--image-w-caption_image--wrapper {
          margin-right: auto !important;
          margin-left: unset !important;
          max-width: 600px;
        }
      }
    }
    
    &:nth-of-type(odd) {
    background: #f8f8f8;

    .page-builder--image-w-caption_image--wrapper {
      .circle-2 {
        display: block;
      }
      .circle-1 {
        display: none;
      }
    }

    }

    .page-builder--image-w-caption_image--wrapper {
      position: relative;
      .circle {
        &-1,
        &-2 {
          position: absolute;
          bottom: -35px;
          left: 0;
          right: 0;
          width: 100%;
          padding: 20px;
          @include media-breakpoint-up(md) {
            bottom: -50px;
          }
        }

        &-2 {
          display: none;
        }
      }
    }
  }

  &.regular {
      background: #f8f8f8;

      .page-builder--image-w-caption_image--wrapper {
        position: relative;
        .circle {
          &-5 {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 45px;
            height: 45px;
          }

          &-7 {
            position: absolute;
            top: 0;
            right: 0;
            height: 80px;
            width: 80px;

            @include media-breakpoint-up(md) {
              width: 130px;
              height: 130px;
            }
          }
        }
      }
  }

  @include media-breakpoint-down(sm) {

    .page-builder--image-w-caption_image--wrapper {
      margin-bottom: 40px;
      width: 320px;
      max-width: 100%;
    }

    .page-builder--image-w-caption_image {
      margin: 0 auto;
    }

    .page-builder--image-w-caption_content-wrapper {
      text-align: center !important;
    }
  }

  &.regular {}

  &.full {

    .page-builder--image-w-caption_content-wrapper {
      @include media-breakpoint-up(md) {
        padding: 0 60px;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 100px;
      }
    }

    &.image-left {
      @include media-breakpoint-up(md) {
        .page-builder--image-w-caption-layout--inner {
            transform: translate3d(-50px, 0, 0); 
        }
      }
    }

    &.image-right {
      @include media-breakpoint-up(md) {
        .page-builder--image-w-caption-layout--inner {
            transform: translate3d(50px, 0, 0); 
        }
      }
    }
  }
}

/*******************************
    Contact Form Block
/*******************************/

.page-builder-contact-block {
  padding: 0px;

  @include media-breakpoint-up(sm) {
    padding: 40px 25px;
  }
}

.pb-cb_inner--wrapper {
  position: relative;

  .pb-cb--image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(139.12deg, #FF8C64 0%, #FF3434 100%);
      opacity: 0.75;
      z-index: -1;
    }
  }

  .pb-cb-form--wrapper {
    width: 610px;
    max-width: 100%;
    margin: 40px auto;
    padding: 20px 25px;
    background-color: #F8F8F8;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    border-radius: $border-radius;

    .gform_wrapper {
      ul.gform_fields li.gfield:not(.gf_left_half) {
        padding-right: 0 !important;
      }

      .gfield_label {
        @include font-size(12);
        color: map-get($theme-colors, secondary);
        font-family: $font-family-roboto;
        margin-bottom: 0;
        font-weight: normal;
      }
  
      .gform_footer {
        padding: 0;
        margin: 20px 0 0 0;
  
        button {
          float: right;
        }
  
        &::after {
          content: '';
          clear: both;
          display: table;
        }
      }
    }
  
    .ginput_container {
  
      input,
      textarea {
        border: 1px solid rgba(0,0,0,0.1);
        padding: 8px 11px;
        border-radius: $border-radius;
  
        &::placeholder {
          color: rgba(0,0,0,0.25);
          @include font-size(16);
          font-family: $font-family-roboto;
        }
      }
  
      // textarea {
      //   margin-top: 12px;
      // }
    }
  }

  .pb-cb-form--title {
    color: map-get($theme-colors, secondary);

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
    }
  }
}

/*******************************
        Awards Block
/*******************************/
.awards {

  &--block {
    padding: 75px 0;
    background: #f8f8f8;
  }

  &-title--wrapper {
    margin-bottom: 65px;
  }

  &--title {
    text-align: center;
    margin-bottom: 0;
  }

  &--item {
    padding: 30px;
    background: #fff;
    width: 320px;
    margin: 15px auto;
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      margin: 0 auto;
    }

    .a {
      &-item-logo--wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      &--item_title {
        color: map-get($theme-colors, secondary);
        @include font-size(16);
      }
    }

  }


}

/*******************************
    Call To Action Block
/*******************************/

.tmp-cta--wrapper {
  padding: 65px;
  background: linear-gradient(139.12deg, #FF8C64 0%, #FF3434 100%), linear-gradient(158.64deg, #F0C987 0%, #F17B5A 49.83%, #FF3434 100%);

  .tmp-cta_inner--wrapper {
    margin-bottom: 30px;

    * {
      color: #fff;

      &.btn {
        background: #fff !important;
        color: map-get($theme-colors, primary) !important;
      }
    }
  }

  .btn.tmp-cta-btn {
    background: #fff !important;
    color: map-get($theme-colors, primary) !important;
    font-weight: 800;
    @include font-size(14);
    letter-spacing: 0.88px;
  }
}