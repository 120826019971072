
* {
  box-sizing: border-box;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100vh;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and ( min-device-pixel-ratio: 1.25), only screen and ( min-resolution: 200dpi), only screen and ( min-resolution: 1.25dppx) {
  html {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

/**
 * Responsive Video Embeds 
 */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/**
 * Remove Mobile IOS Styles
 */
input, textarea, button {
  -webkit-appearance: none;
  -webkit-border-radius:0px;
}

img {
  max-width: 100%;
  height: auto;

}

img {
  max-width: 100%;
  height: auto;
}

@media all and (max-width:768px) {
  *[data-mh] {
      height: auto !important;
  }
}