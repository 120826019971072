.btn,
input[type="submit"],
input[type="button"],
.button,
::-webkit-file-upload-button {
  color: map-get(map-get($button-colors, standard), base-color);
  background-color: map-get(
      map-get($button-colors, standard),
      base-background);
  @include font-size(14);
  
  &:hover {
    color: map-get(map-get($button-colors, standard), hover-color);
    background-color: map-get(
      map-get($button-colors, standard),
      hover-background
    );
  }

  &.btn-primary {
    color: map-get(map-get($button-colors, primary), base-color);
    background-color: map-get(
      map-get($button-colors, primary),
      base-background
    );

    &:hover {
      color: map-get(map-get($button-colors, primary), hover-color);
      background-color: map-get(
        map-get($button-colors, primary),
        hover-background
      );
    }
  }

  &.btn-secondary {
    color: map-get(map-get($button-colors, secondary), base-color);
    background-color: map-get(
      map-get($button-colors, secondary),
      base-background
    );

    &:hover {
      color: map-get(map-get($button-colors, secondary), hover-color);
      background-color: map-get(
        map-get($button-colors, secondary),
        hover-background
      );
    }
  }
}

.btn.dropdown-toggle {
    background: transparent !important;
    border: 1px solid rgba(0,0,0,0.1)!important;
    border-radius: $border-radius !important;
    max-height: 37px;

    &::after {
      border-top: 3px solid rgba(0,0,0,0.5) !important;
    }

    .filter-option {
      &-inner {

      }

      &-inner-inner {
        color: rgba(0,0,0,0.5);
        font-family: $font-family-roboto;
        font-weight: normal;
        @include font-size(14);
      }
    }
}

.ginput_container {
  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
    width: 263px;
    max-width: 100%;
  }
}
// .btn.btn-primary {
//   @include font-size(14);
//   font-weight: 900;
//   letter-spacing: 0.88px;
// }

.gform_next_button,
.gform_previous_button {
  background-color: map-get($theme-colors, primary);
  color: $white;
  padding: 0.5rem 1.5rem;
  @include font-size(16);
  border: none;
  outline: none;
  border-radius: $border-radius;
}

button,
.button,
input[type="submit"],
input[type="button"],
::-webkit-file-upload-button {
  padding: .5rem 1.5625rem;
  border-radius: $border-radius;
  border: none;
  outline: none;
  cursor: pointer;
}

input::placeholder,
textarea {
  color: rgba(0,0,0,0.25);
  @include font-size(16);
  font-family: $font-family-roboto;
}