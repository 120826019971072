/**
 * Typography Styles
 */
body
{
  font-size: 0.875rem; // 14px
  transition: font-size 150ms;

  @include media-breakpoint-up(sm) // min-width: 576px
  {
    font-size: 0.9375rem; // 15px
  }

  @include media-breakpoint-up(md) // min-width: 768px
  {
    font-size: 1rem; // 16px
  }
}


@function map-deep-get($map, $keys...) {
  @each $key in $keys {
      $map: map-get($map, $key);
  }
  @return $map;
}

/**
 * Heading Styles
 */
  @include media-breakpoint-up(sm) {

      h1, .h1 {
        font-size: (map-deep-get($header-styles, 'small', 'h1', 'font-size') / 16) + rem;
        line-height: map-deep-get($header-styles, 'small', 'h1', 'line-height');
        margin-top: map-deep-get($header-styles, 'small', 'h1', 'margin-top');
        margin-bottom: map-deep-get($header-styles, 'small', 'h1', 'margin-bottom');
      }

      h2, .h2 {
        font-size: (map-deep-get($header-styles, 'small', 'h2', 'font-size') / 16) + rem;
        line-height: map-deep-get($header-styles, 'small', 'h2', 'line-height');
        margin-top: map-deep-get($header-styles, 'small', 'h2', 'margin-top');
        margin-bottom: map-deep-get($header-styles, 'small', 'h2', 'margin-bottom');
      }

      h3, .h3 {
        font-size: (map-deep-get($header-styles, 'small', 'h3', 'font-size') / 16) + rem;
        line-height: map-deep-get($header-styles, 'small', 'h3', 'line-height');
        margin-top: map-deep-get($header-styles, 'small', 'h3', 'margin-top');
        margin-bottom: map-deep-get($header-styles, 'small', 'h3', 'margin-bottom');
      }

      h4, .h4 {
        font-size: (map-deep-get($header-styles, 'small', 'h4', 'font-size') / 16) + rem;
        line-height: map-deep-get($header-styles, 'small', 'h4', 'line-height');
        margin-top: map-deep-get($header-styles, 'small', 'h4', 'margin-top');
        margin-bottom: map-deep-get($header-styles, 'small', 'h4', 'margin-bottom');
      }

      h5, .h5 {
        font-size: (map-deep-get($header-styles, 'small', 'h5', 'font-size') / 16) + rem;
        line-height: map-deep-get($header-styles, 'small', 'h5', 'line-height');
        margin-top: map-deep-get($header-styles, 'small', 'h5', 'margin-top');
        margin-bottom: map-deep-get($header-styles, 'small', 'h5', 'margin-bottom');
      }

      h6, .h6 {
        font-size: (map-deep-get($header-styles, 'small', 'h6', 'font-size') / 16) + rem;
        line-height: map-deep-get($header-styles, 'small', 'h6', 'line-height');
        margin-top: map-deep-get($header-styles, 'small', 'h6', 'margin-top');
        margin-bottom: map-deep-get($header-styles, 'small', 'h6', 'margin-bottom');
      }
  }

  @include media-breakpoint-up(md) {
    h1, .h1 {
      font-size: (map-deep-get($header-styles, 'medium', 'h1', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'medium', 'h1', 'line-height');
      margin-top: map-deep-get($header-styles, 'medium', 'h1', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'medium', 'h1', 'margin-bottom');
    }

    h2, .h2 {
      font-size: (map-deep-get($header-styles, 'medium', 'h2', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'medium', 'h2', 'line-height');
      margin-top: map-deep-get($header-styles, 'medium', 'h2', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'medium', 'h2', 'margin-bottom');
    }

    h3, .h3 {
      font-size: (map-deep-get($header-styles, 'medium', 'h3', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'medium', 'h3', 'line-height');
      margin-top: map-deep-get($header-styles, 'medium', 'h3', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'medium', 'h3', 'margin-bottom');
    }

    h4, .h4 {
      font-size: (map-deep-get($header-styles, 'medium', 'h4', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'medium', 'h4', 'line-height');
      margin-top: map-deep-get($header-styles, 'medium', 'h4', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'medium', 'h4', 'margin-bottom');
    }

    h5, .h5 {
      font-size: (map-deep-get($header-styles, 'medium', 'h5', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'medium', 'h5', 'line-height');
      margin-top: map-deep-get($header-styles, 'medium', 'h5', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'medium', 'h5', 'margin-bottom');
    }

    h6, .h6 {
      font-size: (map-deep-get($header-styles, 'medium', 'h6', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'medium', 'h6', 'line-height');
      margin-top: map-deep-get($header-styles, 'medium', 'h6', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'medium', 'h6', 'margin-bottom');
    }
  }

  @include media-breakpoint-up(lg) {
    h1, .h1 {
      font-size: (map-deep-get($header-styles, 'large', 'h1', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'large', 'h1', 'line-height');
      margin-top: map-deep-get($header-styles, 'large', 'h1', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'large', 'h1', 'margin-bottom');
    }

    h2, .h2 {
      font-size: (map-deep-get($header-styles, 'large', 'h2', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'large', 'h2', 'line-height');
      margin-top: map-deep-get($header-styles, 'large', 'h2', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'large', 'h2', 'margin-bottom');
    }

    h3, .h3 {
      font-size: (map-deep-get($header-styles, 'large', 'h3', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'large', 'h3', 'line-height');
      margin-top: map-deep-get($header-styles, 'large', 'h3', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'large', 'h3', 'margin-bottom');
    }

    h4, .h4 {
      font-size: (map-deep-get($header-styles, 'large', 'h4', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'large', 'h4', 'line-height');
      margin-top: map-deep-get($header-styles, 'large', 'h4', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'large', 'h4', 'margin-bottom');
    }

    h5, .h5 {
      font-size: (map-deep-get($header-styles, 'large', 'h5', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'large', 'h5', 'line-height');
      margin-top: map-deep-get($header-styles, 'large', 'h5', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'large', 'h5', 'margin-bottom');
    }

    h6, .h6 {
      font-size: (map-deep-get($header-styles, 'large', 'h6', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'large', 'h6', 'line-height');
      margin-top: map-deep-get($header-styles, 'large', 'h6', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'large', 'h6', 'margin-bottom');
    }
  }

  @include media-breakpoint-up(xl) {

    h1, .h1 {
      font-size: (map-deep-get($header-styles, 'extra-large', 'h1', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'extra-large', 'h1', 'line-height');
      margin-top: map-deep-get($header-styles, 'extra-large', 'h1', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'extra-large', 'h1', 'margin-bottom');
    }

    h2, .h2 {
      font-size: (map-deep-get($header-styles, 'extra-large', 'h2', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'extra-large', 'h2', 'line-height');
      margin-top: map-deep-get($header-styles, 'extra-large', 'h2', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'extra-large', 'h2', 'margin-bottom');
    }

    h3, .h3 {
      font-size: (map-deep-get($header-styles, 'extra-large', 'h3', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'extra-large', 'h3', 'line-height');
      margin-top: map-deep-get($header-styles, 'extra-large', 'h3', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'extra-large', 'h3', 'margin-bottom');
    }

    h4, .h4 {
      font-size: (map-deep-get($header-styles, 'extra-large', 'h4', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'extra-large', 'h4', 'line-height');
      margin-top: map-deep-get($header-styles, 'extra-large', 'h4', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'extra-large', 'h4', 'margin-bottom');
    }

    h5, .h5 {
      font-size: (map-deep-get($header-styles, 'extra-large', 'h5', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'extra-large', 'h5', 'line-height');
      margin-top: map-deep-get($header-styles, 'extra-large', 'h5', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'extra-large', 'h5', 'margin-bottom');
    }

    h6, .h6 {
      font-size: (map-deep-get($header-styles, 'extra-large', 'h6', 'font-size') / 16) + rem;
      line-height: map-deep-get($header-styles, 'extra-large', 'h6', 'line-height');
      margin-top: map-deep-get($header-styles, 'extra-large', 'h6', 'margin-top');
      margin-bottom: map-deep-get($header-styles, 'extra-large', 'h6', 'margin-bottom');
    }
    
  }

/**
 * Paragraphs / spans
 */
p {
  font-size: (18 / 16) + rem;
  font-weight: normal;
  margin-bottom: $paragraphs-margin-bottom;
  line-height: $paragraphs-line-height;

  span {
    font-size: inherit;
    font-weight: inherit;
  }
}

/**
 * Custom li's.
 */
ul {
  margin-bottom: 2em;
  padding-left: 0;

  & > li {
    list-style-type: none;
    margin-bottom: 1em;
    display: block;
    font-size: (18 / 16) + rem;
    
    &:not(.no-dot):not(.gfield) {
      padding-left: 1.3em;
      &::before {
        content: "\f111"; /* Icon Unicode */
        font-size: 5px;
        font-family: FontAwesome; /* Icon Family */
        display: inline-block;
        color: #999999;
        vertical-align: middle;
        width: 3em !important;
        margin-left: -3em !important;
      }
    }

    & > ol:not(.nav-drop), 
    & > ul:not(.nav-drop) {
      margin-top: 1em;
    }
  }
}

ul.dropdown-menu {
  li {
    padding-left: 0 !important;
    margin-bottom: 0;

    &::before {
      content: '';
      display: none !important;
    }
  }
}

