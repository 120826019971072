.background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &.size-contain {
    background-size: contain;
  }
}

.no-decoration {
  &:hover {
    text-decoration: none;
  }
}

.double-column {
  column-count: 2;
}

.color-white {
  color: $white;
}

.remove-last-margin {
  & > :last-child {
    margin-bottom: 0;
  }
}

.radius-half {
  border-radius: 50%;
}

.overlay {
  &::after{ 
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $overlay-normal;
  }

  &.minor {
    &::after {
      background-color: $overlay-minor;
    }
  }
}