// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/*********************************************
    Service Contact Form
/*********************************************/
.sf--wrapper {
  background-color: $white-tertiary;
  padding: 30px;
  border-radius: $border-radius;
  max-width: 100%;
  width: 360px;
  margin: 0 auto;

  @include media-breakpoint-up(xl) {
    margin: 0 0 0 auto;
  }

  .gform_wrapper {
    ul.gform_fields li.gfield {
      padding-right: 0 !important;
    }

    .top_label .gfield_label {
      @include font-size(12);
      margin-bottom: 0px;
      font-family: $font-family-roboto;
      font-weight: normal;
      color: map-get($theme-colors, secondary);
    }

    .gform_footer {
      padding: 0;
      margin: 20px 0 0 0;

      button {
        float: right;
      }

      &::after {
        content: '';
        clear: both;
        display: table;
      }
    }
    
  }

  .ginput_container {

    input,
    textarea {
      border: 1px solid rgba(0,0,0,0.1);
      padding: 8px 11px;
      border-radius: $border-radius;

      &::placeholder {
        color: rgba(0,0,0,0.25);
        @include font-size(16);
        font-family: $font-family-roboto;
      }
    }

    // textarea {
    //   margin-top: 12px;
    // }
  }
}

.sf--title {
  color: map-get($theme-colors, secondary);
  @include font-size(16);
  margin-bottom: 10px;

  @include media-breakpoint-up(md) {
    @include font-size(18);
  }
}

input,
    textarea {
      border: 1px solid rgba(0,0,0,0.1);
      padding: 8px 11px;
      border-radius: $border-radius;

      &::placeholder {
        color: rgba(0,0,0,0.25);
        @include font-size(16);
        font-family: $font-family-roboto;
      }
    }


/*
 *  Gravity Forms Example Form Styles
 */

// #gform_wrapper_1 {
//   form#gform_1 {
//     .gform_body {
//       // Form List Itmes
//       li.gfield {
//       }
//       // Form List Item Labels
//       label.gfield_label {
//       }
//     }

//     .gform_footer {
//       // Submit Button
//       .button {
//       }
//     }
//   }
// }

.gform_footer {
  button {
    cursor: pointer;
  }
}



/*********************************************
    Contact Page Contact Form
/*********************************************/
.contact-page-form--wrapper.contact-page-form.cp {
  background-color: #EFEFEF;
  padding: 40px;
  border-radius: $border-radius;
  max-width: 100%;
  width: 460px;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    margin: 80px auto;
  }

  .gform_wrapper {
    ul.gform_fields li.gfield {
      padding-right: 0 !important;
    }

    .gfield_label {
      @include font-size(12);
      margin-bottom: 0px;
      font-family: $font-family-roboto;
      font-weight: normal;
      color: map-get($theme-colors, secondary);
    }

    .gform_footer {
      padding: 0;
      margin: 20px 0 0 0;

      button {
        float: right;
      }

      &::after {
        content: '';
        clear: both;
        display: table;
      }
    }
  }

  .top_label .ginput_container {
    margin-top: 2px;
  }

  .ginput_container {
    

    input,
    textarea {
      border: 1px solid rgba(0,0,0,0.1);
      padding: 8px 11px;
      border-radius: $border-radius;

      &::placeholder {
        color: rgba(0,0,0,0.25);
        @include font-size(16);
        font-family: $font-family-roboto;
      }
    }

    // textarea {
    //   margin-top: 12px;
    // }
  }
}


/*********************************************
    Visa Form Assessment Wrapper
/*********************************************/
.visa-form_inner--wrapper {

  

}

body .gform_wrapper .top_label .clear-multi div.ginput_container {
  margin-top: 0px;
}

.gform_wrapper {

  .ginput_container {
    margin-top: 5px;

    li::before {
      display: none!important;
    }
  }

  li.gfield {
    margin-top: 30px;
  }

  li.gfield.gfield_error, 
  li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background-color: transparent;
    border-top: 1px solid map-get($theme-colors, primary);
    border-bottom: 1px solid map-get($theme-colors, primary);
  }

  /*********************************************
      ERRORS
  /*********************************************/
  div.validation_error {
    border-top: 2px solid map-get($theme-colors, primary);
    border-bottom: 2px solid map-get($theme-colors, primary);
  }

  div.validation_error,
  .validation_message {
    color: map-get($theme-colors, primary);
  }


  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
  textarea {
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: $border-radius;
  }

  .top_label .gfield_label {
    color: map-get($theme-colors, secondary);
    font-family: $font-family-roboto;
    @include font-size(14);
    font-weight: 300;
    margin-bottom: 0;
  }

  .gfield_required {
    display: none;
  }

  .gform_page_footer {
    border-top: none;
    padding-top: 25px;
  }

}

// Radio Buttons
.gfield_radio {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  &:not(#input_4_18) {

    input[type="radio"] {
      // position: absolute;
      // top: -9999999px;
      // left: -9999999px;
      height: 0;
      margin: 0;
      position: absolute;
      opacity: 0;

      &:checked + label {

        color: map-get($theme-colors, primary);
        font-weight: normal;

        &::before {
          border: 1px solid rgba(0,0,0,0.1);
          background-color: map-get($theme-colors, primary);
        }
        
      }
    }

    label:not(.gfield_label) {
      padding: 4px 30px;
      height: 36px;
      line-height: 1.5;
      max-width: 100%;
      margin-top: 0;
      margin-left: 0; 
      color: rgba(0,0,0,0.25);
      font-family: $font-family-roboto;
      @include font-size(16);
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: $border-radius;
      position: relative;
      transition: color .25s ease-in-out;
      margin-right: 10px;

      &::before {
        content: '';
        height: 10px;
        width: 10px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 10px;
        transform: translateY(-5px);
        border-radius: 50%;
        border: 1px solid #000;
        background-color: transparent;
        transition: background-color .25s ease-in-out;
      }
    }
  }

  .image-choices-choice-image-wrap {
    background-size: contain;
    width: 30px;
    height: 30px;
  }

  // Visas Styling
  &#input_4_18 {
    li {
      width: 160px;
      margin: 0 15px;
      background: transparent;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      box-shadow: 0px 8px 16px rgba(0,0,0,0);
      transition: box-shadow .25s ease-in-out;

      &:hover {
        background: #fff;
        box-shadow: 0px 8px 16px rgba(0,0,0,0.8);
      }

      input {
        position: absolute;
      }

      label {
        height: auto;
        padding: 30px;
        max-width: 100%;
        text-align: center;

        .image-choices-choice-text {
          @include font-size(12);
          line-height: (24 / 16);
          font-family: $font-family-roboto;
          font-weight: normal;
        }
      }
    }
  }
}

.gform_footer {
  padding: 0;
  margin: 20px 0 0 0;

  button,
  input[type="submit"] {
    float: right;
  }

  &::after {
    content: '';
    clear: both;
    display: table;
  }
}

input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
textarea {
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: $border-radius;
}

.gform_wrapper .gfield_date_day, .gform_wrapper .gfield_date_month, .gform_wrapper .gfield_date_year {
  margin-top: 0;
}

@media all and (min-width: 992px) {
	body.blog .blog--wrapper {
		padding-top: 0;
	}
	
	body.blog .page-header_inner--wrapper.news-clients-team .page-header_content--wrapper {
		padding-bottom: 25px;
	}
	
	.block.page-builder.page-builder-banner--block.standard {
		padding: 0 0;
	}
}

.requirements-block .requirements-block-list--wrapper .rb-list--item {
	line-height: 2;
	padding-right: 1.3em;
	padding-top: 7px;
	padding-bottom: 7px;
}

@media all and (max-width: 562px) {
	.gform_wrapper ul.gfield_radio li {
		width: 100%;
		overflow: visible;
		
	}

}
.gfield_radio:not(#input_4_18) label:not(.gfield_label) {
	height: auto;
	min-height: 36px;
}