/**
 * Footer base styles
 * @see footer.twig
 */
.site-footer {
    background: #000;
    padding: 35px 0;

    .free-assessment--wrapper {
        text-align: center;
        margin: 15px 0; 
        .btn-primary {
            @include font-size(12);
            font-weight: bold;
        }
    }
}


/*****************
  Footer Top
/*****************/
.footer-top {
    margin-bottom: 35px;
}


/*****************
  Social
/*****************/

.footer-social--wrapper {

    .social-media {
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        
        &--item {
            margin: 0 7px;
        }

        &--link {
            color: $white;
            @include font-size(16);
            padding: 5px;
        }

    }
}

/********************
  Footer Navigation
/********************/
.footer-navigation--wrapper {

    .navigation-ul {
        padding: 0;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;
            align-items: center;
        }

        @include media-breakpoint-up(lg) {
            justify-content: space-between;
        }
    }

    .nav-main-item {
        display: block;
        margin: 5px 0;
        text-align: center;

        // &:first-of-type {
        //     margin-top: 0;
        // }
    }

    .nav-main-link {
        @include font-size(16);
        font-family: $font-family-roboto;
        font-weight: 300;
        color: $white;
        padding: 5px;
        display: inline-block;
    }
}


/****************
  Footer Middle
/***************/
.footer-middle > .container {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
}


/******************
  Migration Agents
/******************/

.footer-migration-agents--title {
    margin-bottom: 8px;
    @include font-size(16);
    font-family: $headings-secondary-font-family;
}

.footer-migration-agents--wrapper,
.footer-migration-agents--title {
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }
}

.migration-agent {

    &s-list {
        padding: 0;
        margin: 0;
        display: inline-block;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    &-item {
        margin: 0;
        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }

    &-title,
    &-number {
        @include font-size(12);
        display: inline-block;
    }

    &-title {
        min-width: 90px;
        text-align: left;
    }
    &-number {}
}


/******************
  Branding
/******************/
.footer-branding--wrapper {

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @include media-breakpoint-up(lg) {
        justify-content: flex-start;
    }

    
    .footer-logo--wrapper {    
        margin-bottom: 15px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
            display: inline-block;
            width: 50%;

            &.primary {
                float: left;
            }

            &.secondary {
                float: right;
            }
        }

        @include media-breakpoint-up(md) {
            float: none;
        }

        @include media-breakpoint-up(lg) {
            display: block;
            // width: auto;

            &.primary {
                max-width: 160px;
            }

            &.secondary {
                flex-grow: 1;
                max-width: 280px;
            }

            &.primary,
            &.secondary {
                float: none;
            }
        }

        img {
            margin: 0 auto;
            display: block;
            max-height: 100px;

            @include media-breakpoint-up(lg) {
                max-height: 160px;
                // max-width: 150px;
                height: auto;
            }
        }
    }
}

/******************
  Copyright Text
/******************/
.footer-text--wrapper, 
.sc-tagline {

    p, a {
        @include font-size(12);
        color: $white;
    }

    a {
        font-size: inherit;
        &:hover {
            color: $white;
            text-decoration: underline;
        }
    }
}