.page-header {

    &--wrapper {
        @include media-breakpoint-down(xl) {
            padding-top: 30px;
        }
    }

    // Background Image
    &--background {
        background-size: cover;
        background-position: center;

        // Front Page Background Styles
        &.front-page {
            position: absolute;
            top: 0;
            right: 0;
            width: 140vw;
            max-width: 450px;
            transform: translate3d(30%, -15%, 0);
            // filter: blur(1px);
            z-index: -1;
            border-radius: 50%;
            background-repeat: no-repeat;
            opacity: 0.35;
            filter: blur(2px);

            @include media-breakpoint-up(md) {
                opacity: 0.75;
                filter: blur(1px);
                width: 600px;
                max-width: 650vw;
            }

            @include media-breakpoint-up(lg) {
                opacity: 1;
                filter: none;
                width: 75vw;
                transform: translate3d(15%, -5%, 0);
            }

            @include media-breakpoint-up(xl) {
                max-width: 900px;                
            }

            &::after {
                content: '';
                display: block;
                padding-bottom: 100%;
            }
            
        }
    }
}

.page-template-template-front-page .page-header_content--wrapper h1 {
    font-weight: bold;
}

.page-header_content--wrapper {
    padding: 50px 0;
}

.page-title {
    @include font-size(16);
    margin-bottom: 7.5px;

    color: map-get($theme-colors, secondary);
}

.page-feature-title {
    
}

/*******************************
    Front Page Page Header
/*******************************/
.page-template-template-front-page {
    @include media-breakpoint-up(md) {
        .page-header_content--wrapper {
            padding: 150px 0;
        }
    }

    @include media-breakpoint-up(lg) {
        .page-header_content--wrapper {
            padding: 150px 0 200px;
        }
    }
}

/*******************************
    Regular Page Header
/*******************************/
.page-header_content--wrapper {
    margin-top: 15px;
}

.page-header_inner--wrapper.regular {
    margin-top: 25px;
    margin-bottom: 2em;

    @include media-breakpoint-up(md) {
        margin-bottom: 100px;
        .page-introduction {
            margin-bottom: 80px;
        }
    }

    .page-header_content--wrapper {
        padding: 0;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }

        @include media-breakpoint-up(lg){
            // transform: translateY(-50%);
        }
    }

    .ph-feature--wrapper {
        max-width: 100%;
        width: 200px;
        position: relative;

        .regular-ph-circle {
            position: absolute;

            &-1 {
                bottom: -10px;
                right: -40px;
                width: 125px;
                height: 125px;

                @include media-breakpoint-up(lg) {
                    height: 200px;
                    width: 200px;
                }

                @include media-breakpoint-up(xl) {
                    width: 300px;
                    height: 300px;
                    right: -120px;
                    bottom: -20px;
                }
            }
            &-5 {
                width: 45px;
                height: 45px;
                top: 0;
                right: 0;
            }
            &-6 {
                bottom: 10px;
                left: -20px;
                width: 100px;
                height: 100px;

                @include media-breakpoint-up(md) {
                    height: 150px;
                    width: 150px;
                    bottom: 30px;
                    left: -50px;
                }

                @include media-breakpoint-up(lg) {
                    width: 200px;
                    height: 200px;
                }
            }
        }

        @include media-breakpoint-up(md) {
            width: 350px;
        }

        @include media-breakpoint-up(lg) {
            width: 400px;
        }

        .page-header--feature {
            width: 100%;
        }
    }
}

/*********************************************
    Service Landing/Child Page Header
/*********************************************/
.page-header_inner--wrapper.service-landing {
    padding: 30px 0;

    @include media-breakpoint-up(lg) {
        padding: 190px 0;
        
        &.has-contact-form {
            padding: 70px 0;
        }
    }
    
    & > .background-image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .page-title,
    .page-feature-title {
        color: $white;
    }

    .page-header_content--wrapper {
        padding: 0;
    }
}

/*********************************************
    News // Clients // Team Page Header
/*********************************************/
.page-header_inner--wrapper.news-clients-team {
    .background-image {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-size: contain;
        background-position: top right;
        z-index: -1;

        @include media-breakpoint-up(lg) {
            background-size: 1000px auto;
            transform: translate3d(100px, 0px, 0);
        }
    }

    .page-header_content--wrapper {
        @include media-breakpoint-up(lg) { 
            padding: 200px 0 100px;
        }
    }
}

body.blog {
    .page-header_inner--wrapper.news-clients-team {
        .page-header_content--wrapper {
            @include media-breakpoint-up(lg) { 
                padding: 200px 0 25px;
            }
        }
    } 
}

body.page-template-template-contact {
    .site-header {
        margin-bottom: 80px;
    }
}

.page-header_inner--wrapper.contact-page {
    height: 350px;

    @include media-breakpoint-up(lg) {
        height: 425px;
    }

    .contact-page-header--map {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
}

/*********************************************
    VISA FORM HEADER
/*********************************************/
.page-header_inner--wrapper.visa-form-header {
    .background-image {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-size: contain;
        background-position: top right;
        z-index: -1;
        opacity: 0.3;
        filter: blur(2px);

        @include media-breakpoint-up(md) {
            opacity: 1;
            filter: none;
        }

        @include media-breakpoint-up(lg) {
            background-size: 450px auto;
            transform: translate3d(100px, 0px, 0);
        }
    }

    .page-header_content--wrapper {
        @include media-breakpoint-up(lg) { 
            padding: 80px 0 100px;
        }
    }
}