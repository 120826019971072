.tool-pagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 120px;

  .pages {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;

    .pages-item {
      margin: 0 10px;

      span {
        @include font-size(18);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        height: 48px;
        width: 48px;
        text-align: center;
        line-height: 0;
        border-radius: 50%;
        background-color: map-get($theme-colors, primary);
      }
    }

    .pages-item span,
    .pages-link {
      font-family: helvetica, sans-serif;
    }


    .pages-link {
        
    }

    .pages-link {
      color: rgba(0,0,0,0.5);
    }
  }

  .pagination-icon--wrapper {
    width: 48px;
    height: 48px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.1);

    a {
      line-height: 0;
      i {
        color: rgba(0,0,0,0.5);
        @include font-size(24);
        line-height: 0;
        font-weight: solid;
      }
    }
  }
}

.blog--wrapper {
  margin-bottom: -15px;
  margin-top: -15px;
  padding: 50px 0 80px;

  @include media-breakpoint-up(lg) {
    padding: 50px 0 0;
  }
}

article.type-post {
  padding: 32px;
  border: 1px solid rgba(0,0,0,0.05);
  margin: 15px auto;
  width: 550px;
  max-width: 100%;
  cursor: pointer;
  transition: box-shadow .25s ease;
  box-shadow: -2px -1px 5px transparent;


  &:hover {
    box-shadow: -2px 2px 6px rgba(0,0,0,.15);
    
    footer .entry-link {
      color: map-get($theme-colors, primary);
    }
  }
 
  .entry-title a,
  .entry-link {
    color: map-get($theme-colors, secondary);
  }

  .entry-title a {
    @include font-size(28);
    line-height: 1.2142857143;

    &:hover {
      text-decoration: none;
    }
  }

  .time,
  .entry-link {
    font-family: $font-family-muli;
  }

  .time {
    color: rgba(0,0,0,0.5);
    font-weight: bold;
    @include font-size(12);
    margin-bottom: 16px;
    display: block;
  }

  .entry-summary {
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0,0,0,0.1);

    p, a {
      @include font-size(16);
      line-height: 1.5;
    }
    
    .read-more {
      display: none;
    }
  }

  footer {
    margin-top: 12px;

    .entry-link {
      font-weight: 800;
      letter-spacing: 0.75px;
      @include font-size(12);
      transition: color .25s linear;
    }
  }

}

/**************************
   Related Posts Styling
/**************************/

.related-posts {

  &--wrapper {
    margin: 36px 0;

    @include media-breakpoint-up(lg) {
      width: 263px;
      max-width: 100%;
    }
  }

  &-title--wrapper {}
  &--title {
    color: rgba(0,0,0,0.25);
    @include font-size(12);
    letter-spacing: 0.75px;
    font-weight: bold;
    line-height: (20 / 12);
  }
}

.related-post {

  &--article {

    border-bottom: 1px solid #EFEFEF;
    padding: 15px 0;

    .entry-title {
      line-height: (16 / 24);
      margin-bottom: 33px;
      & a {
        @include font-size(16);
        color: map-get($theme-colors, secondary);
        font-weight: bold;

        &:hover {
          text-decoration: none;
          color: map-get($theme-colors, primary);
        }
      }
    }
    
    .related-post--link {
      @include font-size(12);
      color: map-get($theme-colors, secondary);
      letter-spacing: 0.75px;
      line-height:(12 / 20);
      font-weight: 800;
      margin: 0;

      i {
        margin-left: 20px;
      }


      &:hover {
        color: map-get($theme-colors, primary);
        text-decoration: none;
        
      }
    }

  }

}


/**************************
   Single Team Posts
/**************************/
.single-tm-image--wrapper {
  width: 320px;
  max-width: 100%;
  margin: 0 auto 35px;

  @include media-breakpoint-up(md) {
    width: 380px;
    margin: 0 0 0 auto;
  }
}
.single-tm-feature--image {
  width: 100%;
}

body.single-team {
  .single-post--wrapper {
    margin-top: 80px;
  }

  .entry-title {
    margin-bottom: 20px;
  }

  .single-post--wrapper   {
    @include media-breakpoint-up(md) {
      margin-top: 50px;
    }

    article {
      margin: 20px 0;

      @include media-breakpoint-up(md) {
        margin: 100px 0;
      }
    }
  }
}

.single-tm {
  &-image--wrapper {
    position: relative;
  }

  &-circle {
    position: absolute;

    &-6 {
      top: 0;
      left: 0;
      width: 93px;
      height: 93px;
    }

    &-5 {
      top: 0;
      right: 0;
      width: 45px;
      height: 45px;
    }

    &-2 {
      left: 0;
      bottom: 0;
      width: 120px;
      height: 120px;
      z-index: 5;

      @include media-breakpoint-up(lg) {
        width: 150px;
        height: 150px;
      }
    }
  }

  &--title {
    
    @include font-size(20);
    font-weight: 800;
    margin-bottom: 8px;
    line-height: 1.3;
  }

  &--education {
    color: rgba(0,0,0,0.5);
    @include font-size(20);
    line-height: 1.3;
  }

  &-introduction--wrapper {
    margin-bottom: 30px;
  }
}

// social
.single-tm-social {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 0;
  margin: 0 0 45px;

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }

  &--item {
    margin: 0 8px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  &--link {
    color: map-get($theme-colors, secondary);
    padding: 3px;

    &:hover {
      text-decoration: none;

      .single-tm-social--icon {
        color: map-get($theme-colors, primary);
      }
    }
  }

  &--icon {
    @include font-size(15);
  }

}
